import React from "react";
import {Create, Datagrid, Edit, List, SelectInput, SimpleForm, TextField, TextInput,} from "react-admin";
import {ROLES} from "../../Constants";

export const AdminUserList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="phoneNumber" />
      <TextField source="role" />
      {/* <TextField source="id" /> */}
    </Datagrid>
  </List>
);
export const AdminUserEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <SelectInput
        source="role"
        choices={[
          { id: ROLES.SUPER_ADMIN, name: "ویژه" },
          { id: ROLES.FINANCIAL, name: "مالی" },
          { id: ROLES.INVOICE, name: "پیگیری" },
        ]}
      />
      <TextInput disabled={true} source="phoneNumber" />
      {/* <TextInput source="role" /> */}
    </SimpleForm>
  </Edit>
);

export const AdminUserCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput
        source="role"
        choices={[
          { id: ROLES.SUPER_ADMIN, name: "ویژه" },
          { id: ROLES.FINANCIAL, name: "مالی" },
          { id: ROLES.INVOICE, name: "پیگیری" },
        ]}
      />
      <TextInput source="phoneNumber" />
      {/* <TextInput source="role" /> */}
    </SimpleForm>
  </Create>
);
