import React, {useState} from "react";
import {
    Button,
    Card,
    CardContent,
    Chip, FormControl, InputLabel, MenuItem,
    Paper, Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import {Search as SearchIcon} from "@material-ui/icons";
import {Loading, useDataProvider} from "react-admin";
import {PersianDatePicker} from "../../Components/PersianDatePickers";
import {CustomExporter} from "../../Components/Exporter";

const initData = [
  {
    count: "",
    id: "",
    phoneNumbers: [],
    prices: [],
  },
];

export default (props) => {
  const dataProvider = useDataProvider();
  const [productUniqueResNum, setProductUniqueResNum] = useState(53);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState(initData);
  const [fromDate, setFromDate] = useState(
    new Date(Date.now() - (604800000).toString())
  );
  const [toDate, setToDate] = useState(new Date());

  // const translate = useTranslate();

  const onSubmit = (data) => {
    setLoading(true);
    setError("");
    data.toDate = new Date(Date.parse(data.toDate)).setHours(0, 0, 0);
    data.fromDate = new Date(Date.parse(data.fromDate)).setHours(0, 0, 0);
    // console.log(data, "data");
    dataProvider
      .getList("sku/report", {
        pagination: { page: 1, perPage: 5 },
        sort: { field: "title", order: "ASC" },
        filter: { author_id: 12 },
        interval: { from: data.fromDate, to: data.toDate,productUniqueResNums:productUniqueResNum},
      })
      .then((res) => {
        const { data } = res;

        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        setError({ error: `چیزی پیدا نشد: ${err?.message()}` });
        setLoading(false);
      });
  };
  return (
    <Card>
      {/*<Title title="Welcome to the administration" />*/}
      <CardContent>
        <div>
          <Paper style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <PersianDatePicker
                name="from"
                value={fromDate}
                onChange={setFromDate}
                label={"از"}
              />
              <span style={{ margin: "0 1em" }}>
                <PersianDatePicker
                  name="from"
                  value={toDate}
                  onChange={setToDate}
                  label={"تا"}
                />
              </span>
                <FormControl variant="filled" style={{minWidth:200,marginLeft:"5px"}} >
                    <InputLabel> شماره بانک محصول </InputLabel>
                    <Select
                        defaultValue="53"
                        onChange={(e)=>{
                            setProductUniqueResNum(e.target.value)
                        }}
                    >
                        <MenuItem value={53}>KARAFS_NEW</MenuItem>
                        <MenuItem value={54}>KARAFS_OLD</MenuItem>
                        <MenuItem value={26}>BENTO</MenuItem>
                        <MenuItem value={22}>DENJ</MenuItem>
                        <MenuItem value={47}>GYM</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div>
              <Button
                onClick={() => onSubmit({ toDate, fromDate })}
                type="submit"
                variant="contained"
                color="primary"
              >
                جست‌وجو
                <SearchIcon />
              </Button>
              <CustomExporter
                resource={props.resource}
                data={{ fromDate: fromDate, toDate: toDate }}
              />
            </div>
          </Paper>
        </div>
      </CardContent>
      <>
        {loading ? (
          <Loading />
        ) : error ? (
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>خطایی پیش آمد</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  موردی یافت نشد
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : (
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">شناسه</TableCell>
                <TableCell align="center">فروش کل</TableCell>
                <TableCell align="center">تعداد</TableCell>
                <TableCell align="center">قیمت‌ها</TableCell>
                {/* <TableCell align="center">شماره‌تلفن ها</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((element) => {
                return (
                  <TableRow key={element._id}>
                    <TableCell align="center" scope="row">
                      {element?._id}
                    </TableCell>
                    <TableCell align="center" scope="row">
                      {element?.totalSold}
                    </TableCell>
                    <TableCell align="center" scope="row">
                      {element?.count}
                    </TableCell>
                    <TableCell width={"400px"} align="center" scope="row">
                      {element?.prices.length > 1 ? (
                        <Chip
                          // icon={icon}
                          label={element.prices[0]}
                          style={{ margin: "2px" }}
                        />
                      ) : (
                        <Chip
                          // icon={icon}
                          label={"نامشخص"}
                          style={{ margin: "2px" }}
                        />
                      )}
                    </TableCell>
                    {/* <TableCell width={"400px"} align="center" scope="row">
                      {element?.phoneNumbers.map((phoneNumber) => (
                        <Chip
                          // icon={icon}
                          label={phoneNumber}
                          style={{ margin: "2px" }}
                        />
                      ))}
                    </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </>
    </Card>
  );
};
