import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import { SERVER } from "../Constants";

const apiUrl = SERVER;
const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("Authorization");
  options.headers.set("Authorization", `${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get("X-Total-Count").split("/").pop(), 10),
    }));
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get("X-Total-Count").split("/").pop(), 10),
    }));
  },

  update: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "DELETE",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
};

export default dataProvider;

export const enhancedDataProvider = {
  getList: (resource, params) => {
    switch (resource) {
      case "bank/conflict/invoices": {
        console.log(resource, params);
        return null;
      }
      case "invoice": {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
          // sort: JSON.stringify([field, order]),
          // range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
          // filter: JSON.stringify(params.filter),
          from: JSON.stringify(params.interval.from),
          to: JSON.stringify(params.interval.to),
          shamsiDiffHour: JSON.stringify(params.interval.shamsiDiffHour),
          productUniqueResNum: JSON.stringify(params.interval.productUniqueResNum),
          paid: JSON.stringify(params.interval.paidQuery),
        };
        const url = `${apiUrl}/${resource}/report?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
          data: json.map((resource) => ({
            ...resource,
            id: resource.invoiceNumber,
          })),
          // total: parseInt(headers.get("X-Total-Count").split("/").pop(), 10),
        }));
      }
      case "sku/report":

        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        // console.log(moment(params.filter?.from._d).valueOf());
        // const nFilter = {
        //   ...params.filter,
        //   from: params.filter.from
        //     ? moment(params.filter?.from._d).valueOf()
        //     : new Date().getTime(),
        //   to: params.filter.to
        //     ? moment(params.filter?.from._d).valueOf()
        //     : new Date().getTime(),
        // };
        // const { from, to } = params.interval;
        const query = {
          sort: JSON.stringify([field, order]),
          range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
          // filter: JSON.stringify(nFilter),
          // from: nFilter.from,
          // to: nFilter.to,
          from: JSON.stringify(params.interval.from),
          to: JSON.stringify(params.interval.to),
          productUniqueResNum : JSON.stringify(params.interval.productUniqueResNums),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
          data: json.map((resource) => ({ ...resource, id: resource._id })),
          total: 10,
        }));

      case "adminUsers": {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
          sort: JSON.stringify([field, order]),
          range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
          filter: JSON.stringify(params.filter),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
          data: json.map((resource) => ({
            ...resource,
            id: resource.phoneNumber,
          })),
          total: parseInt(headers.get("X-Total-Count").split("/").pop(), 10),
        }));
      }
      default:
        return dataProvider.getList(resource, params);
    }
  },
  getOne: (resource, params) => {
    switch (resource) {
      case "invoice": {
        const query = {
          invoiceId: params.id,
        };
        return httpClient(`${apiUrl}/${resource}/?${stringify(query)}`).then(
          ({ json }) => ({
            data: { ...json, id: json.invoiceNumber },
          })
        );
      }
      case "sku/report": {
        return httpClient(`${apiUrl}/${resource}/${params.id}`).then(
          ({ json }) => ({
            data: { ...json, id: json._id },
          })
        );
      }
      default:
        return dataProvider.getOne(resource, params);
    }
  },

  getMany: (resource, params) => {
    switch (resource) {
      case "sku/report": {
        const query = {
          filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({
          data: json.map((resource) => ({ ...resource, id: resource._id })),
        }));
      }
      default:
        return dataProvider.getMany(resource, params);
    }
  },

  getManyReference: (resource, params) => {
    switch (resource) {
      case "sku/report": {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
          sort: JSON.stringify([field, order]),
          range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
          filter: JSON.stringify({
            ...params.filter,
            [params.target]: params.id,
          }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
          data: json.map((resource) => ({ ...resource, id: resource._id })),
          total: parseInt(headers.get("X-Total-Count").split("/").pop(), 10),
        }));
      }
      default:
        return dataProvider.getManyReference(resource, params);
    }
  },

  update: (resource, params) => {
    switch (resource) {
      case "sku/report": {
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
          method: "PUT",
          body: JSON.stringify(params.data),
        }).then(({ json }) => ({
          data: { ...json, id: json._id },
        }));
      }
      default:
        return dataProvider.update(resource, params);
    }
  },

  updateMany: (resource, params) => {
    switch (resource) {
      case "sku/report": {
        const query = {
          filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
          method: "PUT",
          body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
      }
      default:
        return dataProvider.updateMany(resource, params);
    }
  },
  create: (resource, params) => {
    switch (resource) {
      case "bank/conflict/invoices": {
        console.log(params)
        if (params.data.files) {
          console.log(params.data);
          const { rawFile } = params.data.files;
          const query = {
            to: params.data.to,
            from: params.data.from,
            productUniqueResNum :params.data.productUniqueResNums
          };
          const formData = new FormData();
          formData.append("file", rawFile);
          return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: "POST",
            body: formData,
          }).then(({ json }) => ({
            data: json,
          }));
        }
        return null;
      }
      case "sku/report": {
        return httpClient(`${apiUrl}/${resource}`, {
          method: "POST",
          body: JSON.stringify(params.data),
        }).then(({ json }) => ({
          data: { ...params.data, id: json._id },
        }));
      }
      default:
        return dataProvider.create(resource, params);
    }
  },
  delete: (resource, params) => {
    switch (resource) {
      case "sku/report": {
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
          method: "DELETE",
        }).then(({ json }) => ({
          data: { ...json, id: json._id },
        }));
      }
      default:
        return dataProvider.delete(resource, params);
    }
  },

  deleteMany: (resource, params) => {
    switch (resource) {
      case "sku/report": {
        const query = {
          filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
          method: "DELETE",
          body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
      }
      default:
        return dataProvider.deleteMany(resource, params);
    }
  },
};
