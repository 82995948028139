import React from "react";
import { Admin, Resource } from "react-admin";
import "./App.css";
import _LoginPage from "./Components/LoginPage";
import { authProvider } from "./providers/authProvider";
import { enhancedDataProvider } from "./providers/dataProvider";
import { SkuCreate, SkuEdit, SkuList, SkuShow } from "./Panels/Sku/Sku";
import { i18nProvider } from "./providers/i18nProvider";
import { myTheme } from "./Styles/theme";
import {
  AdminUserCreate,
  AdminUserEdit,
  AdminUserList,
} from "./Panels/AdminUsers/AdminUsers";
import SkuReport from "./Panels/Sku/SkuReport";
import { InvoiceReport } from "./Panels/Invoice/InvoiceReport";
import { InvoiceShow } from "./Panels/Invoice/InvoiceShow";
import { ROLES } from "./Constants";
import ConflictInBank from "./Panels/Conflict/ConflictInBank";

function App() {
  return (
    <Admin
      // dashboard={DashBoard}
      dataProvider={enhancedDataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      loginPage={_LoginPage}
      theme={myTheme}
    >
      {(permissions) => [
        permissions === ROLES.SUPER_ADMIN || permissions === ROLES.INVOICE ? (
          <Resource
            list={InvoiceReport}
            show={InvoiceShow}
            name="invoice"
            options={{ label: "گزارش پیگیری" }}
          />
        ) : null,
        permissions === ROLES.SUPER_ADMIN || permissions === ROLES.FINANCIAL ? (
          <Resource
            list={SkuReport}
            name="sku/report"
            options={{ label: "گزارش مالی" }}
          />
        ) : null,
        permissions === ROLES.SUPER_ADMIN || permissions === ROLES.INVOICE ? (
          <Resource
            list={ConflictInBank}
            // show={InvoiceShow}
            name="bank/conflict/invoices"
            options={{ label: "مغایرت " }}
          />
        ) : null,
        permissions === ROLES.SUPER_ADMIN ? (
          <Resource
            list={SkuList}
            edit={SkuEdit}
            show={SkuShow}
            create={SkuCreate}
            name="sku"
          />
        ) : null,
        permissions === ROLES.SUPER_ADMIN ? (
          <Resource
            list={AdminUserList}
            edit={AdminUserEdit}
            create={AdminUserCreate}
            name="adminUsers"
            options={{ label: "مدیریت کاربران" }}
          />
        ) : null,
      ]}
    </Admin>
  );
}

export default App;
