import {createMuiTheme} from "@material-ui/core/styles";
import teal from "@material-ui/core/colors/teal";
import red from "@material-ui/core/colors/red";

export const myTheme = createMuiTheme({
  direction: "rtl",
  palette: {
    type: "light",
    primary: {
      light: "#4CA96D",
      main: "#227135",
      dark: "#1E5533",
      contrastText: "#fff",
    },
    secondary: {
      main: teal[800],
    },
    failure: {
      main: red[800],
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: "IRANSansWeb",
    fontSize: 16,
  },
  overrides: {
    MuiTableCell: {
      root: {
        textAlign: "right",
      },
    },
    MuiTableRow: {
      head: {
        backgroundColor: "lightgray",
        "& > th ": {
          color: "black",
          fontFamily: "IRANSansWeb",
          fontWeight: "bold",
          textAlign: "right",
        },
      },
    },
    MuiInputLabel: {
      formControl: {
        // right: "20px",
        left: "auto",
      },
      shrink: {
        transformOrigin: "top right",
        "&$filled": {
          "&$marginDense": { transform: "translate(-12px, 17px) scale(0.75)" },
        },
      },
      filled: {
        transform: "translate(-12px, 17px) scale(1)",
        "&$shrink": { transform: "translate(-12px, 7px) scale(0.75)" },
        "&$marginDense": {
          transform: "translate(-12px, 17px) scale(1)",
          "&$shrink": { transform: "translate(-12px, 7px) scale(0.75)" },
        },
      },
    },
  },
});
