import React, {useState} from "react";
import {useDataProvider, useNotify, useRedirect, required } from "react-admin";
import {Button, Card, CardContent, CardHeader, Paper, TextField,} from "@material-ui/core";
import {Search as SearchIcon} from "@material-ui/icons";

SearchInvoice.propTypes = {};

function SearchInvoice(props) {
  const [invoice, setInvoice] = useState("");
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const handleSearch = () => {
    dataProvider
      .getOne("invoice", { id: invoice })
      .then((res) => {
        notify("انتقال به صفحه پیگیری");
        redirect(`/invoice/${invoice}/show`);
      })
      .catch((err) => {
        notify(`خطایی رخ داد: ${err.message}`);
      });
  };
  return (
    <Card style={{ margin: "1em 0" }}>
      {/*<Title title="Welcome to the administration" />*/}
      <CardHeader title={"جستجوی صورت حساب"} />
      <CardContent>
        <>
          <Paper
            variant={"outlined"}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <TextField
               validate={required()}
              label="شماره شناسه صورتحساب"
              variant="filled"
              fullWidth
              onChange={(e) => setInvoice(e.target.value)}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSearch}
            >
              جست‌وجو
              <SearchIcon />
            </Button>
          </Paper>
        </>
      </CardContent>
    </Card>
  );
}

export default SearchInvoice;
