import {makeStyles} from "@material-ui/core/styles";

const overrideRaStyles = {
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 48,
    padding: "0 30px",
  },
  securePan: {
    direction: "ltr",
    // background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    display: "block",
  },
};
const useStyles = makeStyles(overrideRaStyles);

export default useStyles;
