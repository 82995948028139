const { SERVER } = require("../Constants");
export const authProvider = {
  login: ({ username, password }) => {
    const req = new Request(`${SERVER}/login`, {
      method: "POST",
      body: JSON.stringify({ phoneNumber: username, code: password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(req)
      .then((res) => {
        if (res.status < 200 || res.status >= 300) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((auth) => {
        const { Authorization, role } = auth;
        localStorage.setItem("Authorization", Authorization);
        localStorage.setItem("role", role);
      });
  },
  logout: () => {
    localStorage.removeItem("Authorization");
    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem("Authorization")
      ? Promise.resolve()
      : Promise.reject(),
  getPermissions: () => {
    const role = localStorage.getItem("role");
    return role ? Promise.resolve(role) : Promise.reject();
  },
};
