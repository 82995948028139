import React, {useState} from "react";
import {
  Create,
  FileField,
  FileInput,
  SimpleForm,
  Toolbar,
  SaveButton,
  downloadCSV,required
} from "react-admin";
import jsonExport from "jsonexport/dist";

import {FormControl, InputLabel, MenuItem, Paper, Select, Typography} from "@material-ui/core";
import { MaterialDateInput } from "../../Components/RaMaterialDatePickers";

function ConflictInBank(props) {
  const [productUniqueResNum, setProductUniqueResNum] = useState(53);
  const onSuccess = ({ data }) => {
    jsonExport(data, (err, csv) => {
      downloadCSV(csv, `conflict ${Date.now()}`);
    });
  };
  const transform = (data) => {
    console.log(data)
    let from = new Date(Date.parse(data.from)).setHours(0, 0, 0).toString();
    let to = new Date(Date.parse(data.to)).setHours(0, 0, 0).toString();
    let productUniqueResNums = productUniqueResNum
    return { ...data, from, to , productUniqueResNums};
  };
  return (
    <Paper>
      <Create {...props} transform={transform} onSuccess={onSuccess}>
        <SimpleForm {...props} toolbar={<CToolbar />}>
          <MaterialDateInput source={"from"} validate={required()}/>
          <MaterialDateInput source={"to"} validate={required()}/>
          <FormControl variant="filled" style={{minWidth:200,marginLeft:"5px",marginTop:15}} >
            <InputLabel> شماره بانک محصول </InputLabel>
            <Select
                defaultValue="53"
                onChange={(e)=>{
                  setProductUniqueResNum(e.target.value)
                }}
            >
              <MenuItem value={53}>KARAFS_NEW</MenuItem>
              <MenuItem value={54}>KARAFS_OLD</MenuItem>
              <MenuItem value={26}>BENTO</MenuItem>
              <MenuItem value={22}>DENJ</MenuItem>
              <MenuItem value={47}>GYM</MenuItem>
            </Select>
          </FormControl>
          <FileInput source="files" label="فایل csv مربوطه" accept=".csv" validate={required()}>
            <FileField source="src" title="title" />
          </FileInput>
          <Typography>
            فایل خروجی csv به صورت خودکار دانلود خواهد شد.
          </Typography>
        </SimpleForm>
      </Create>
    </Paper>
  );
}

export default ConflictInBank;

const CToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="ارسال و جستجو" redirect={null} submitOnEnter={false} />
  </Toolbar>
);
