import React from "react";
import {
    BooleanField,
    DateField,
    Labeled,
    NumberField,
    ShowController,
    ShowView,
    SimpleShowLayout,
    TextField,
    useTranslate,
} from "react-admin";
import {Grid, Typography} from "@material-ui/core";
import useStyles from "../../Components/Hooks/useStyles";

export const InvoiceShow = (props) => {
  return (
    <ShowController {...props}>
      {(controllerProps) => (
        <ShowView {...props} {...controllerProps}>
          {controllerProps.record &&
          controllerProps.record.samanInvoiceResult ? (
            <SimpleShowLayout>
              <NumberField source="amount" />
              <NumberField source="invoiceNumber" />
              <DateField locales={"fa-IR"} source="invoiceDate" />
              <BooleanField source="payed" />
              <TextField source="sku" />
              <TextField source="phoneNumber" />
              <Typography
                variant="h6"
                gutterBottom
                style={{ background: "lightgray" }}
              >
                اطلاعات درگاه
              </Typography>
              {/* <TextField source="samanInvoiceResult.Amount" />
              <TextField source="samanInvoiceResult.MID" />
              <TextField source="samanInvoiceResult.RRN" />
              <TextField source="samanInvoiceResult.RefNum" />
              <TextField source="samanInvoiceResult.SecurePan" />
              <TextField source="samanInvoiceResult.State" />
              <TextField source="samanInvoiceResult.TRACENO" />
              <TextField source="samanInvoiceResult.TerminalId" />
              <TextField source="samanInvoiceResult.Token" />
              <TextField source="samanInvoiceResult.website" /> */}
              <GateWayResult />
              <Typography
                variant="h6"
                gutterBottom
                style={{ background: "lightgray" }}
              >
                اطلاعات برنامه
              </Typography>
              <TextField source="viewData.app" />
              <TextField source="viewData.expirationDate" />
              <TextField source="viewData.subscriptionAmount" />
            </SimpleShowLayout>
          ) : (
            <SimpleShowLayout>
              <NumberField source="amount" />
              <NumberField source="invoiceNumber" />
              <DateField locales={"fa-IR"} source="invoiceDate" />
              <BooleanField source="payed" />
              <TextField source="sku" />
              <TextField source="phoneNumber" />
              <Typography
                variant="h6"
                gutterBottom
                style={{ background: "lightgray" }}
              >
                اطلاعات برنامه
              </Typography>
              <TextField source="viewData.app" />
              <TextField source="viewData.expirationDate" />
              <TextField source="viewData.subscriptionAmount" />
            </SimpleShowLayout>
          )}
        </ShowView>
      )}
    </ShowController>
  );
};

function GateWayResult(props) {
  const classes = useStyles();
  const translate = useTranslate();
  const { record, resource, ...rest } = props;
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Labeled
          label={translate(
            `resources.${resource}.fields.samanInvoiceResult.Amount`
          )}
        >
          <NumberField source="samanInvoiceResult.Amount" record={record} />
        </Labeled>
      </Grid>
      <Grid item>
        <Labeled
          label={translate(
            `resources.${resource}.fields.samanInvoiceResult.MID`
          )}
        >
          <TextField source="samanInvoiceResult.MID" record={record} />
        </Labeled>
      </Grid>
      <Grid item>
        <Labeled
          label={translate(
            `resources.${resource}.fields.samanInvoiceResult.RRN`
          )}
        >
          <TextField source="samanInvoiceResult.RRN" record={record} />
        </Labeled>
      </Grid>
      <Grid item>
        <Labeled
          label={translate(
            `resources.${resource}.fields.samanInvoiceResult.RefNum`
          )}
        >
          <TextField source="samanInvoiceResult.RefNum" record={record} />
        </Labeled>
      </Grid>
      <Grid item>
        <Labeled
          className={classes.securePan}
          label={translate(
            `resources.${resource}.fields.samanInvoiceResult.SecurePan`
          )}
        >
          <TextField
            className={classes.securePan}
            source="samanInvoiceResult.SecurePan"
            record={record}
          />
        </Labeled>
      </Grid>
      <Grid item>
        <Labeled
          label={translate(
            `resources.${resource}.fields.samanInvoiceResult.State`
          )}
        >
          <TextField source="samanInvoiceResult.State" record={record} />
        </Labeled>
      </Grid>
      <Grid item>
        <Labeled
          label={translate(
            `resources.${resource}.fields.samanInvoiceResult.TRACENO`
          )}
        >
          <TextField source="samanInvoiceResult.TRACENO" record={record} />
        </Labeled>
      </Grid>
      <Grid item>
        <Labeled
          label={translate(
            `resources.${resource}.fields.samanInvoiceResult.TerminalId`
          )}
        >
          <TextField source="samanInvoiceResult.TerminalId" record={record} />
        </Labeled>
      </Grid>
      <Grid item>
        <Labeled
          label={translate(
            `resources.${resource}.fields.samanInvoiceResult.Token`
          )}
        >
          <TextField source="samanInvoiceResult.Token" record={record} />
        </Labeled>
      </Grid>
      <Grid item>
        <Labeled
          label={translate(
            `resources.${resource}.fields.samanInvoiceResult.website`
          )}
        >
          <TextField source="samanInvoiceResult.website" record={record} />
        </Labeled>
      </Grid>
    </Grid>
  );
}
GateWayResult.defaultProps = {
  addLabel: false,
};
