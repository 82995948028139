import React, {useEffect, useReducer, useState} from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  InputLabel
} from "@material-ui/core";
import {Search as SearchIcon} from "@material-ui/icons";
import {Loading, useDataProvider,downloadCSV,useNotify} from "react-admin";
import {PersianDatePicker} from "../../Components/PersianDatePickers";
import SearchInvoice from "../../Components/SearchInvoice";
import {CustomExporter} from "../../Components/Exporter";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import {axiosFetcher} from "../../utils/axiosFetcher";
import jsonExport from "jsonexport/dist";

const ACTION_TYPES = {
  setLoading: "setLoading",
  setError: "setError",
  setData: "setData",
  setViewData: "setViewData",
  setFromDate: "setFromDate",
  setToDate: "setToDate",
  setShamsiDate :"setShamsiDate",
  setMultiple: "setMultiple",
  setFilter: "setFilter",
};
const initState = {
  isLoading: false,
  error: "",
  data: [],
  viewData: [],
  fromDate: new Date(),
  toDate: new Date(),
  shamsiDate: "",
  filter: "",
};

function reducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.setLoading:
      return { ...state, isLoading: action.payload };
    case ACTION_TYPES.setError:
      return { ...state, error: action.payload };
    case ACTION_TYPES.setData:
      return { ...state, data: action.payload };
    case ACTION_TYPES.setViewData:
      return { ...state, viewData: action.payload };
    case ACTION_TYPES.setFromDate:
      return { ...state, fromDate: action.payload };
    case ACTION_TYPES.setToDate:
      return { ...state, toDate: action.payload };
    case ACTION_TYPES.setShamsiDate:
      return { ...state, shamsiDate: action.payload };
    case ACTION_TYPES.setFilter:
      return { ...state, filter: action.payload };
    case ACTION_TYPES.setMultiple:
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
}
export function InvoiceReport(props) {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [fromDate, setFromDate] = useState(new Date());
  const [paid ,setPaid]=useState("true")
  const [toDate, setToDate] = useState(new Date());
  const [shamsiDate, setShamsiDate] = useState(4.5);
  const [productUniqueResNum, setProductUniqueResNum] = useState(53);
  const [state, dispatch] = useReducer(reducer, initState);
  const [loading, setLoading] = useState(false);
  const { filter } = state;
  useEffect(() => {
    if (!filter) {
      return dispatch({ type: ACTION_TYPES.setViewData, payload: state.data });
    } else {
      let newData = state.data.filter((item) => {
        return item[filter] === true;
      });
      return dispatch({ type: ACTION_TYPES.setViewData, payload: newData });
    }
  }, [filter]);

  const onSubmit = (data) => {
    notify(" منتظر بمانید");
    setLoading(true)
    data.toDate = new Date(Date.parse(data.toDate)).setHours(0, 0, 0);
    data.fromDate = new Date(Date.parse(data.fromDate)).setHours(0, 0, 0);
    axiosFetcher
        .get(`/invoice/report?from=${data.fromDate}&to=${data.toDate}&paid=${paid}&shamsiDiffHour=${shamsiDate}&productUniqueResNum=${productUniqueResNum}`)
        .then((res) => {
          notify("انجام شد");
          setLoading(false)
          jsonExport(res.data, (err, csv) => {
            downloadCSV(csv, `invoice${Date.now()}`);
          });
        })
        .catch((e) => {
          setLoading(false)
          notify(" مشکلی پیش آمد");
        });
    dispatch({ type: ACTION_TYPES.setLoading, payload: true });
    dispatch({ type: ACTION_TYPES.setError, payload: "" });

   data.shamsiDate= shamsiDate
    dataProvider
      .getList("invoice", {
        pagination: { page: 1, perPage: 5 },
        sort: { field: "title", order: "ASC" },
        filter: { author_id: 12 },
        interval: {
          from: data.fromDate,
          to: data.toDate ,
          shamsiDiffHour: shamsiDate,
          productUniqueResNum :productUniqueResNum,
          paidQuery : paid
        },
      })
      .then((res) => {

        console.log(res)

      })
      .catch((err) => {
        dispatch({
          type: ACTION_TYPES.setLoading,
          payload: { isLoading: false, error: "چیزی پیدا نشد" },
        });
      });
  };
  return (
    <>
      <SearchInvoice />
      <Card>
        <CardHeader title={"گزارش‌ صورت‌حساب‌ها در یک دوره زمانی"} />
        <CardContent>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <PersianDatePicker
                  name="from"
                  value={fromDate}
                  onChange={setFromDate}
                  label={"از"}
                />
                <span style={{ margin: "0 0.3em" }} />
                <PersianDatePicker
                  name="from"
                  value={toDate}
                  onChange={setToDate}
                  label={"تا"}
                />
                <span style={{ margin: "0 0.3em" }} />
                <FormControl variant="filled" style={{minWidth:200,marginLeft:"5px"}} >
                  <InputLabel>اختلاف زمانی با UTC </InputLabel>
                  <Select
                      defaultValue="4.5"
                      onChange={(e)=>{
                        setShamsiDate(e.target.value)
                      }}
                  >
                    <MenuItem value={3.5}>3.5 (نیمه دوم سال)</MenuItem>
                    <MenuItem value={4.5}>4.5 (نیمه اول سال)</MenuItem>
                  </Select>
                </FormControl>
                  <FormControl variant="filled" style={{minWidth:200,marginLeft:"5px"}} >
                  <InputLabel> شماره بانک محصول </InputLabel>
                  <Select
                      defaultValue="53"
                      onChange={(e)=>{
                        setProductUniqueResNum(e.target.value)
                      }}
                  >
                    <MenuItem value={53}>KARAFS_NEW</MenuItem>
                    <MenuItem value={54}>KARAFS_OLD</MenuItem>
                    <MenuItem value={26}>BENTO</MenuItem>
                    <MenuItem value={22}>DENJ</MenuItem>
                    <MenuItem value={47}>GYM</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="filled" style={{minWidth:200,marginLeft:"5px"}} >
                  <InputLabel> پرداخت شده</InputLabel>
                  <Select
                      defaultValue="true"
                      onChange={(e)=>{
                        setPaid(e.target.value)
                      }}
                  >
                    <MenuItem value="true">بله</MenuItem>
                    <MenuItem value="false">خیر</MenuItem>
                  </Select>
                </FormControl>
                <span style={{ margin: "0 0.3em" }} />
                {state.viewData.length > 0 ?
                 <FormControl style={{ minWidth: "100px",float: "left", marginBottom : "" }}>
                  {/*<InputLabel id="select-label">فیلتر</InputLabel>*/}
                  <Select
                    displayEmpty
                    labelId="elect-filled-label"
                    id="select-filled"
                    value={state.filter}
                    onChange={(e) =>
                      dispatch({
                        type: ACTION_TYPES.setFilter,
                        payload: e.target.value,
                      })
                    }
                    variant={"filled"}
                  >
                    <MenuItem value="">
                      <em>فیلتر</em>
                    </MenuItem>
                    <MenuItem value={"payed"}>پرداخت شده</MenuItem>
                  </Select>
                </FormControl> :null}
          
              </div>
              <div style={{ display: "flex", }}>
                <Button onClick={() => onSubmit({ toDate, fromDate })}
                  type="submit"
                        variant="contained"
                        color="primary"
                >
                  جست و جو و دریافت
                </Button>
                  {/*    <CustomExporter*/}
                  {/*    resource={props.resource}*/}
                  {/*    data={{ fromDate: fromDate, toDate: toDate }}*/}
                  {/*    exportData={state.viewData}*/}
                  {/*/>*/}
              </div>
            </div>
            {loading==true  ? <Loading/> :null}

            {/*<Paper style={{ margin: "2em 0px" }}>*/}
            {/*  <>*/}
            {/*    {state.isLoading ? (*/}
            {/*      <Loading />*/}
            {/*    ) : state.error ? (*/}
            {/*      <Table aria-label="simple table">*/}
            {/*        <TableHead>*/}
            {/*          <TableRow>*/}
            {/*            <TableCell>خطایی پیش آمد</TableCell>*/}
            {/*          </TableRow>*/}
            {/*        </TableHead>*/}
            {/*        <TableBody>*/}
            {/*          <TableRow>*/}
            {/*            <TableCell component="th" scope="row">*/}
            {/*              خطایی پیش آمد*/}
            {/*            </TableCell>*/}
            {/*          </TableRow>*/}
            {/*        </TableBody>*/}
            {/*      </Table>*/}
            {/*    ) : (*/}
            {/*      <Table aria-label="simple table">*/}
            {/*        <TableHead>*/}
            {/*          <TableRow>*/}
            {/*            <TableCell align="center">مقدار</TableCell>*/}
            {/*            /!*<TableCell align="center">محصول</TableCell>*!/*/}
            {/*            <TableCell align="center">شماره پیگیری</TableCell>*/}
            {/*            <TableCell align="center">پرداخت شده</TableCell>*/}
            {/*            <TableCell align="center">SKU</TableCell>*/}
            {/*            <TableCell align="center">درگاه</TableCell>*/}
            {/*            <TableCell align="center">شماره تلفن</TableCell>*/}
            {/*            <TableCell align="center">تاریخ پیگیری</TableCell>*/}
            {/*            <TableCell align="center">تاریخ پرداخت</TableCell>*/}
            {/*          </TableRow>*/}
            {/*        </TableHead>*/}
            {/*        <TableBody>*/}
            {/*          {state.viewData.length > 0 ? (*/}
            {/*            state.viewData.map((element) => {*/}
            {/*              return (*/}
            {/*                <TableRow key={element.id}>*/}
            {/*                  <TableCell align="center" scope="row">*/}
            {/*                    {element?.amount}*/}
            {/*                  </TableCell>*/}
            {/*                  /!*<TableCell align="center" scope="row">*!/*/}
            {/*                  /!*  {element?.productCallback}*!/*/}
            {/*                  /!*</TableCell>*!/*/}
            {/*                  <TableCell align="center" scope="row">*/}
            {/*                    {element?.invoiceNumber}*/}
            {/*                  </TableCell>*/}

            {/*                  <TableCell align="center" scope="row">*/}
            {/*                    {element?.payed ? (*/}
            {/*                      <DoneIcon style={{ color: green[500] }} />*/}
            {/*                    ) : (*/}
            {/*                      <CloseIcon style={{ color: red[500] }} />*/}
            {/*                    )}*/}
            {/*                  </TableCell>*/}
            {/*                  <TableCell align="center" scope="row">*/}
            {/*                    {element?.sku}*/}
            {/*                  </TableCell>*/}
            {/*                  <TableCell align="center" scope="row">*/}
            {/*                    {element?.chosenPG}*/}
            {/*                  </TableCell>*/}
            {/*                  <TableCell align="center" scope="row">*/}
            {/*                    {element?.phoneNumber}*/}
            {/*                  </TableCell>*/}
            {/*                  <TableCell align="center" scope="row">*/}
            {/*                    {new Date(element?.invoiceDate).toLocaleString(*/}
            {/*                      "fa-IR"*/}
            {/*                    )}*/}
            {/*                  </TableCell>*/}
            {/*                  <TableCell align="center" scope="row">*/}
            {/*                    {new Date(element?.paymentDate).toLocaleString(*/}
            {/*                      "fa-IR"*/}
            {/*                    )}*/}
            {/*                  </TableCell>*/}

            {/*                </TableRow>*/}
            {/*              );*/}
            {/*            })*/}
            {/*          ) : (*/}
            {/*            <Typography style={{ padding: "0.5em" }}>*/}
            {/*              موردی یافت نشد*/}
            {/*            </Typography>*/}
            {/*          )}*/}
            {/*        </TableBody>*/}
            {/*      </Table>*/}
            {/*    )}*/}

            {/*  </>*/}
            {/*</Paper>*/}
          </div>
    
        </CardContent>
      </Card>
    </>
  );
}
