import React, { useState } from "react";
import exportFromJSON from "export-from-json";
import { useDataProvider } from "react-admin";
import { Button } from "@material-ui/core";
import { ArrowDownward } from "@material-ui/icons";

export const CustomExporter = (props) => {
  const { resource, data } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  let fileName = `${data.fromDate} - ${data.toDate}`;
  const dataProvider = useDataProvider();

  function fetchData() {
    data.toDate = new Date(Date.parse(data.toDate)).setHours(24, 0, 0);
    data.fromDate = new Date(Date.parse(data.fromDate)).setHours(24, 0, 0);
    return dataProvider.getList(resource, {
      pagination: { page: 1, perPage: 200 },
      sort: { field: "title", order: "ASC" },
      // filter: { author_id: 12 },
      interval: { from: data.fromDate, to: data.toDate },
    });
  }
  function exportToExcel(fetchedData) {
    let jsonData;
    jsonData = JSON.stringify(
      fetchedData.map((item) => {
        return {
          date: item.paymentDate,
          sku: item.sku,
          amount: item.amount,
          payed: item.payed,
        };
      })
    );

    return exportFromJSON({
      data: jsonData,
      fileName: fileName,
      exportType: exportFromJSON.types.xls,
    });
  }
  const _handleExport = () => {
    setLoading(true);
    if (props.exportData) {
      exportToExcel(props.exportData);
    } else {
      fetchData()
        .then((res) => {
          const { data: resData } = res;
          exportToExcel(resData);
          setLoading(false);
        })
        .catch((err) => {
          setError({ error: `چیزی پیدا نشد: ${err?.message}` });
          setLoading(false);
        });
    }
  };
  return (
    <Button
      onClick={() => _handleExport()}
      type="submit"
      variant="text"
      color="primary"
      loading={loading}
    >
      دریافت خروجی
      <ArrowDownward />
    </Button>
  );
};
