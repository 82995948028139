import React, {useState} from "react";
import {Notification, useLogin, useNotify, required } from "react-admin";
import {ThemeProvider} from "@material-ui/styles";
import {Card} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import {SERVER} from "../Constants";
import {phoneValidate} from "../validation";

const _LoginPage = ({ theme }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [btnText, setBtnText] = useState("درخواست کد");
  const [invalidPhone, setInvalidPhone] = useState(false);
  const login = useLogin();
  const notify = useNotify();
  const submit = (e) => {
    e.preventDefault();
    if (phoneValidate(phoneNumber).valid) {
      setLoading(true);
      if (btnText === "درخواست کد") {
        axios
          .post(`${SERVER}/requestCode`, {
            phoneNumber: phoneNumber,
          })
          .then((res) => {
            if (res.status < 200 || res.status >= 300) {
              throw new Error(res.statusText);
            } else {
              setBtnText("تایید و ورود");
              setLoading(false);
              notify("karafs.general.send_code", "info");
            }
          })
          .catch((err) => {
            setLoading(false);
            notify("karafs.general.generic_err", "warning");
          });
      } else {
        login({ username: phoneNumber, password: code }).catch(() => {
          setLoading(false);
          notify("ra.auth.sign_in_error", "warning");
        });
      }
    } else {
      setInvalidPhone(true);
      notify("karafs.general.invalid_phone_number", "warning");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          height: "1px",
          alignItems: "center",
          // justifyContent: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundImage: " linear-gradient(to top, #37ecba 0%, #72afd3 100%)",
        }}
      >
        {/* <div
          style={{
            minWidth: "65vw",
            minHeight: "80vh",
            backgroundColor: "rgba(255,255,255,0.8)",
            backgroundImage: `url(${bg})`,
            backgroundPosition: "bottom",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            borderRadius: 25,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "2px 5px 12px 0px rgba(105,105,105,1)",
          }}
        > */}
        <Card
          style={{
            minWidth: 400,
            margin: "8em",
            padding: "1em 1em 0 1em",
            backgroundColor: "rgba(255,255,255,0.7)",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            backgroundPosition: "bottom",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
          elevation={5}
        >
          <div
            style={{
              margin: "1em",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          ></div>
          <Typography
            style={{ textAlign: "center", marginBottom: "0.8em" }}
            variant="h6"
          >
            کالری‌شمار کرفس
          </Typography>
          <form
            onSubmit={submit}
            style={{
              padding: "0 1em 1em 1em",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField    validate={required()}
              disabled={btnText === "تایید و ورود"}
              style={{ margin: "0 0 0.5em 0" }}
              name="phone"
              label="شماره تلفن"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              variant="filled"
            />
            <TextField
              style={{ margin: "0.5em 0" }}
              name="code"
              label="کد تاییدیه"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              variant="filled"
              disabled={btnText === "درخواست کد"}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              style={{ margin: "1em 0", padding: "0.8em 0" }}
            >
              {loading ? (
                <CircularProgress size={22} color="inherit" />
              ) : (
                btnText
              )}
            </Button>
          </form>
        </Card>
      </div>
      {/* </div> */}
      <Notification />
    </ThemeProvider>
  );
};

export default _LoginPage;
