import React, {useState} from "react";
import {
  Create,
  Datagrid,
  Edit,
  List,
  NumberField,
  NumberInput, SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useDataProvider,
  useTranslate,
  Filter,
  useNotify
} from "react-admin";
import {Button, Card, CardContent, Paper,} from "@material-ui/core";
import {Search as SearchIcon} from "@material-ui/icons";
import {PersianDatePicker} from "../../Components/PersianDatePickers";
import {SkuReportFilter} from "./CustomList";
import {axiosFetcher} from "../../utils/axiosFetcher";


import { SERVER } from "../../Constants"; 
console.log(SERVER);
const CompanyProductUniqueResNumType = [
    { id: 53, name: "KARAFS_NEW" },
    { id: 54 , name: "KARAFS_OLD" },
    { id: 26, name: "BENTO" },
    { id: 22, name: "DENJ" },
    { id: 47, name: "GYM" },

];

const initData = [
  {
    count: "",
    id: "",
    phoneNumbers: [],
    prices: [],
  },
];
const PostFilter = (props) => (
    <Filter {...props}>
        <SelectInput
            source="productUniqueResNum"
            choices={CompanyProductUniqueResNumType}
        />
    </Filter>
);
const ShopPackageCondition = (props) => {
  const { record, resource, ...rest } = props;
  const notify = useNotify();
  const handleDuplicate  = () => {
    console.log(props.record.id)
    axiosFetcher
    .post(`${SERVER}/sku`, {name : props.record.name,paymentAmount : props.record.paymentAmount, productUniqueResNum: props.record.productUniqueResNum ,sku:props.record.sku + "-" })
    .then((res) => {
      window.open(`https://adminpanel.pay.karafsapp.com/#/sku/${res.data.id}/show`, '_blank')
    })
    .catch((e) => {
      notify("مشکلی پیش آمد")
    });
  }
  return(
    <>
      <Button
        onClick={handleDuplicate}
        type="submit"
        variant="text"
        color="primary"
        style={{background  :"whiteSmoke"}}
        >
        دوپلیکیت
      </Button>
    </>
  )
}
export const SkuReport = (props) => {

  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState(initData);
  const [fromDate, setFromDate] = useState(
    new Date().setTime(new Date().getTime() - 604800000)
  );
  const [toDate, setToDate] = useState(new Date());

  const translate = useTranslate();
  const onSubmit = (data) => {
    setLoading(true);
    setError("");
    // data.toDate = Date.parse(data.toDate);
    // data.fromDate = Date.parse(data.fromDate);
    data.toDate = new Date(Date.parse(data.toDate)).setHours(0, 0, 0);
    data.fromDate = new Date(Date.parse(data.fromDate)).setHours(0, 0, 0);
    dataProvider
      .getList("sku/report", {
        pagination: { page: 1, perPage: 5 },
        sort: { field: "title", order: "ASC" },
        filter: { author_id: 12 },
        interval: { from: data.fromDate, to: data.toDate },
      })
      .then((res) => {
        const { data } = res;
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        setError({ error: "چیزی پیدا نشد" });
        setLoading(false);
      });
  };
  
  return (
    <Card>
      <CardContent>
        <div>
          <Paper style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <PersianDatePicker
                name="from"
                value={fromDate}
                onChange={setFromDate}
                label={"از"}
              />
              <span style={{ margin: "0 1em" }}>
                <PersianDatePicker
                  name="from"
                  value={toDate}
                  onChange={setToDate}
                  label={"تا"}
                />
              </span>
            </div>
            <div>
              <Button
                onClick={() => onSubmit({ toDate, fromDate })}
                type="submit"
                variant="contained"
                color="primary"
              >
                جست‌وجو
                <SearchIcon />
              </Button>
              <Button
                onClick={() => onSubmit({ toDate, fromDate })}
                type="submit"
                variant="text"
                color="secondary"
              >
                دریافت خروجی
                <SearchIcon />
              </Button>
            </div>
          </Paper>
        </div>
      </CardContent>
      <List {...props} filters={<SkuReportFilter />}>
        <Datagrid rowClick="show">
          <TextField source="id" />
          <NumberField source="totalSold" />
          <NumberField source="count" />
        </Datagrid>
      </List>
    </Card>
  );
};


export const SkuList = (props) => (
  <List {...props} filters={<PostFilter/>}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <NumberField source="paymentAmount" />
      <TextField source="sku"  dir="ltr"  />
      <TextField source="productUniqueResNum" />
    </Datagrid>
  </List>
);

export const SkuEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="name" />
      <NumberInput source="paymentAmount" />
      <TextInput source="sku" />
      <SelectInput label="شماره بانک محصول " choices={CompanyProductUniqueResNumType} source="productUniqueResNum"/>
    </SimpleForm>
  </Edit>
);

export const SkuShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <NumberField source="paymentAmount" />
      <TextField source="sku"  dir="ltr"  />
      <TextField source="productUniqueResNum" />
      <ShopPackageCondition {...props} />
    </SimpleShowLayout>
  </Show>
);

export const SkuCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <NumberInput source="paymentAmount" />
      <TextInput source="sku" />
      <SelectInput label="شماره بانک محصول " choices={CompanyProductUniqueResNumType} source="productUniqueResNum"/>
    </SimpleForm>
  </Create>
);
